
const constants = {
  submitbtn: 'Submit',
  cashierSubmitbtn: 'Submit Without Redeem',
  rewardSubmitbtn: 'Submit Issue Reward',
  EarnRequestbtn: 'Earn Button',
  RedeemRequestbtn: 'Redeem Button',
  otpHelper: `Please choose the mobile number or the email address associated with your account and We
        will send you an OTP.`,
  return2SignIn: 'Return to sign in',
  withOTPTypography: 'Login With OTP',
  pointearnChart: 'Points Earn & Redeem Trend',
  QRflow: {
    GenerateOTP: 'Get OTP',
  },
  login: {
    btn1: 'Login',
    btn2: 'Login with OTP',
    loginSubTypography: 'Sign in to 1 Loyalty',
    typographies: {
      body2: 'New user?',
      subtitle2: 'Create an account',
    },
    forgot: 'Forgot password?',
    withOTPAuthFailed: 'incorrect OTP. Try again',
  },
  resetPassword: {
    title: 'Forgot your password?',
    typography:
      'Please enter the mobile number associated with your account and We will send you a link to reset your password.',
    resend: 'Resend code',
  },
  forgotPswrd: {
    title: 'Create new password',
    update: 'Update Password',
  },
  account: {
    changePswrd: {
      passwordLimiterText: 'Password must be minimum 8+',
    },
    saveChanges: 'Save Changes',
  },
  sendReq: 'Send Request',
  verify: 'Verify',
  signup: {
    title: 'Reward Your Obsession',
    link: 'Sign in',
    existingAcc: 'Already have an account? ',
    userHelper: 'email address will be your username',
    btn: 'Create account',
    existing: 'User exist with this number. Please sign in',
    success: 'Account created successfully. Your email is your username. Please login!',
  },
  logout: {
    title: 'Logout',
    loggedOut: 'Logout successfully',
  },
  enroll: {
    title: 'Member List',
    new: 'New Member',
    clear: 'Clear',
    delete: 'Delete',
    noData: 'No Data Found. Search a member or add new member',
  },
  error: {
    wrongPassword: 'Your new password must be different from previous used passwords',
    serverError: 'Something went wrong. Please try again later.',
  },
  chartMapper: <any>{
    'Pie Chart': 'donut',
    line: 'line',
  },
  manageStores: {
    placeholder: 'Search Stores by name',
    inActive: 'Inactive',
    disableAccount: 'Apply disable account',
    list: {
      title: 'Stores List',
      newBtn: 'New Store',
    },
    create: {
      title: 'Create a new store',
      btn: 'Create Store',
    },
    edit: {
      title: 'Edit Store Details',
      btn: 'Save Changes',
    },
    noData: 'No Data Found. Search a store code or add new store',
  },
  profile: {
    serviceName: 'MANAGE_CUSTOMER_USER',
    successMessage: 'Profile updated successfully!',
  },
  chshierportal: {
    title: 'Cashier Portal',
    titletwo: 'Issue Reward',
  },
  others: {
    TransactionHistory: 'Import Transaction History',
    CustomerHistory: 'Import Customer History',
  },
  Requestportal: {
    EarnRequest: 'Submit Request',
    RedeemRequest: 'Redeem Request',
  },
  customerDashboard: {
    noData: 'Please fill all the fields to search member',
  },
  report: {
    noData: 'No Data Found',
  },
};
export const userMapper: { [key: string]: string } = {
  CustomerUser: 'Customer User',
  CrmUser: 'CRM User',
  ClientAdmin: 'ClientAdmin',
  SystemAdmin: 'SystemAdmin',
  StoreAdmin: 'StoreAdmin',
  Manufacturer: 'Manufacturer',
  Distributor:'Distributor',
  Dealer: 'Dealer',
  Influencer: 'Influencer'

};

export const userRole = {
  CustomerUser: 'Customer User',
  CrmUser: 'CRM User',
  ClientAdmin: 'ClientAdmin',
  SystemAdmin: "SystemAdmin",
  StoreAdmin: 'StoreAdmin',
  Manufacturer: 'Manufacturer',
  Distributor:'Distributor',
  Dealer: 'Dealer',
  Influencer: 'Influencer',
  AnonymousCustomer: 'AnonymousCustomer',
}

export default constants;

/* Drop Down */
export const daysFilter = [
  { key: 'today', value: 'Today' },
  { key: 'yesterday', value: 'Yesterday' },
  { key: 'last7days', value: 'Last 7 Days' },
  { key: 'last30days', value: 'Last 30 Days' },
  { key: 'thismonth', value: 'This Month' },
  { key: 'lastmonth', value: 'Last Month' },
  { key: 'last3months', value: 'Last 3 Months' },
  { key: 'last6months', value: 'Last 6 Months' },
  { key: 'last12months', value: 'Last 12 Months' },
  { key: 'customrange', value: 'Custom Range' },
];

export const daysFilterForRFM = [
  { key: 'today', value: 'Today' },
  { key: 'yesterday', value: 'Yesterday' },
  { key: 'last7days', value: 'Last 7 Days' },
  { key: 'last30days', value: 'Last 30 Days' },
  { key: 'thismonth', value: 'This Month' },
  { key: 'lastmonth', value: 'Last Month' },
  { key: 'last3months', value: 'Last 3 Months' },
  { key: 'last6months', value: 'Last 6 Months' },
  { key: 'last12months', value: 'Last 12 Months' },
  { key: 'customrange', value: 'Custom Range' },
];

export const RFMFilterRecency = [
  {key: 'Day', value: 'Day'},
]

export const RFMFilterFrequency = [
  {key: 'Visit', value: 'Visits'},
]

export const RFMFilterMonetary = [
  {key: 'Spend', value: 'Spend'},
]

export const daysFilterCustomer = [
  {key: 'last30days', value: 'Last 30 Days'},
  {key: 'last60days', value: 'Last 60 Days'},
  {key: 'last3months', value: 'Last 3 Month'},
  {key: 'last6months', value: 'Last 6 Months'},
  {key: 'last12months', value: 'Last 12 Months'},
]

/* eslint-disable no-useless-escape */
// ---- regular expressions--------
export const phoneRegExp = /^(\+[\d]{1,5}|0)?[6-9]\d{9}$/;

export const emailRegExp =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const pswrdRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
