const BASE_URL_FOR_MENU: string = `${process.env.REACT_APP_CAMPAIGN_API}/campaign-web/portal/api/v1`;
const BASE_URL_FOR_MENU_FEEDBACK: string = `${process.env.REACT_APP_CAMPAIGN_API}/campaign-web/feedback/api/v1`;
const BASE_URL: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/ui/v1`;
const BASE_URL_CASHIERPORTAL: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/gateway/cashierportal/v1`;
const BASE_URL_STSTEM: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/system/v1`;
const BASE_URL_REPORT: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/reports/v1`;
const BASE_URL_RULE_ENGINE: string = `${process.env.REACT_APP_RULE_ENGINE}/portal/v1`;
const BASE_URL_NO_TOKEN: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/ui/v1/unauth`;
const BASE_URL_FOR_ISSUE: string = `${process.env.REACT_APP_CAMPAIGN_API}/campaign-web/api/v1`;
// const BASE_URL_FOR_AFFILIATE: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/cashierportal/v1`;
const BASE_URL_FOR_WF_AFFILIATE: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/gateway/workflow/v1`;
const UNAUTH_BASE_URL: string = `${process.env.REACT_APP_1LOYALTY_API}/1LoyaltyApi/unauth/ui/v1`;
const BASE_URL_QR_CLIENT: string = `${process.env.REACT_APP_CAMPAIGN_API}/campaign-web/client/api/v1`;
const BASE_URL_QR_KEY: string = `${process.env.REACT_APP_CAMPAIGN_API}/campaign-web/qrcode/api/v1`;
const BASE_URL_INVOICE: string = `${process.env.REACT_APP_CAMPAIGN_API}/campaign-web/api/v1/invoices`;

const endpoints: { [Key: string]: string } = {
  getAccessToken: `${BASE_URL}/oauth/token`,
  getUIConfigApi: `${BASE_URL}/get-ui-config`,

  login: `${BASE_URL}/users/login`,
  loginWithInstrument: `${BASE_URL}/users/login-with-instrument`,
  loginWithInstrumentValidate: `${BASE_URL}/users/login-with-instrument-validate`,
  logout: `${BASE_URL}/users/logout`,

  changePwrd: `${BASE_URL}/users/change-password`,
  forgotPwrd: `${BASE_URL}/users/forgot-password`,
  signup: `${BASE_URL}/users/signup`,
  userSearch: `${BASE_URL}/users/search`,
  generateOTP: `${BASE_URL}/users/generate-otp`,
  validateOTP: `${BASE_URL}/users/validate-otp`,

  getMembershipRewards: `${BASE_URL}/users/get-membership-rewards`,
  transactionHistory: `${BASE_URL}/transaction/history`,
  getClientDashboard: `${BASE_URL}/users/get-client--dashboard`,
  getStores: `${BASE_URL}/store/get-list`,
  maintainStores: `${BASE_URL}/store`,
  getUserList: `${BASE_URL}/users/list`,
  getLoggedinUserProfile: `${BASE_URL}/users/profile`,

  // Admin Dashboard
  getUserSignupAnalytics: `${BASE_URL}/user/dashboard/get-signup-analytics`,
  getSalesRewardsAnalytics: `${BASE_URL}/user/dashboard/get-sales-rewards-analytics`,
  getUserSalesAnalytics: `${BASE_URL}/user/dashboard/get-sales-analytics`,
  getTierwiseActiveMembers: `${BASE_URL}/user/dashboard/get-tierwise-active-members`,
  getTierwiseSales: `${BASE_URL}/user/dashboard/get-tierwise-sales`,
  getPointBalanceStats: `${BASE_URL}/user/rewards-statistics`,
  getStorePerformace: `${BASE_URL}/user/analytics/store-performance`,
  // View Analytics
  getBusinessSalesAnalytics: `${BASE_URL}/user/analytics/business`,
  getSalesByChannelsAnalytics: `${BASE_URL}/user/analytics/sales-by-channel`,

  // drop downs
  getGeographyByPincode: `${BASE_URL}/geography/get-geography-by-pincode`,
  getPinCodeList: `${BASE_URL}/geography/get-pincode-list`,
  getClientList: `${BASE_URL}/client/get-list`,
  getProgramList: `${BASE_URL}/program/get-list`,
  getStoreList: `${BASE_URL}/store/get-name-list`,
  getClientListAutoFill: `${BASE_URL}/client/get-list-autofill`,
  // cashierportal
  getCustomerByInstrument: `${BASE_URL_CASHIERPORTAL}/get-customer-by-instrument`,
  getMembershipDetail: `${BASE_URL_CASHIERPORTAL}/get-membership-detail`,
  generateOTPForCashier: `${BASE_URL_CASHIERPORTAL}/generate-otp`,
  validateForCashierPortalOTP: `${BASE_URL_CASHIERPORTAL}/validate-otp`,
  earnRedeemRewards: `${BASE_URL_CASHIERPORTAL}/earn-redeem-rewards`,
  getMenuCampain: `${BASE_URL_FOR_MENU}/client/get-menu`,
  getOTP: `${BASE_URL_STSTEM}/get-otp`,
  cancelEarnRedeemRewards: `${BASE_URL_CASHIERPORTAL}/cancel-earn-redeem-rewards`,
  returnEarnRedeemRewards: `${BASE_URL_CASHIERPORTAL}/return`,
  issueRewards: `${BASE_URL_FOR_ISSUE}/issue-reward`,
  // customer user dashboard
  getCustomerMembers: `${BASE_URL}/user/dashboard/get-members`,
  getCustomerrewards: `${BASE_URL}/user/dashboard/get-rewards`,
  getCustomerUsertrasactions: `${BASE_URL}/user/transaction/history`,
  getTransactionDeatils: `${BASE_URL}/user/transaction/detail`,
  getCustomerUserAddRewards: `${BASE_URL_FOR_MENU}/user/additional-reward/history`,
  // master Date and resport
  getMasterData: `${BASE_URL}/masterdata/get-name-list`,
  getFraudDetection: `${BASE_URL_REPORT}/fraud-detection`,
  updateBTN: `${BASE_URL}/users/update-fileds`,
  getDownloadReports: `${BASE_URL_REPORT}/download`,
  getConfigReport: `${BASE_URL_REPORT}/get-config`,
  getRunReports: `${BASE_URL_REPORT}/run`,
  // rfm analytics
  getAnalyticsCustomFilter: `${BASE_URL}/user/analytics/custom-filter/get-list`,
  createAndupdatanalytics: `${BASE_URL}/user/analytics/custom-filter`,
  getSlabsCustomFilter: `${BASE_URL}/user/analytics/custom-filter/get`,
  getCustomFilterApply: `${BASE_URL}/user/analytics/custom-filter/apply`,
  getResultCustomerFilter: `${BASE_URL}/user/analytics/custom-filter/apply/result`,
  downloadRFMResult: `${BASE_URL}/user/analytics/custom-filter/apply/result/download`,
  itemsAnalytics:`${BASE_URL}/user/analytics/items-analytics`,
  // File Processing and Other
  getFileProcessingList: `${BASE_URL}/system/file-processing/list`,
  fileProcessingDownload: `${BASE_URL}/system/file-processing/download`,
  fileProcessingUpload: `${BASE_URL}/system/file-processing/upload`,

  // Campaign
  getCampaignPerformance: `${BASE_URL_FOR_MENU}/campaign/performance`,
  getCampaignList: `${BASE_URL_FOR_MENU}/campaign/list`,
  createCampaignList: `${BASE_URL_FOR_MENU}/campaign`,
  createCampaignUpdate: `${BASE_URL_FOR_MENU}/campaign`,
  campaignConditionList: `${BASE_URL_RULE_ENGINE}/conditionList`,
  campaignActionList: `${BASE_URL_RULE_ENGINE}/actionList`,
  campaignTheme: `${BASE_URL_FOR_MENU}/manage-theme`,
  campaignUploadTargetGroup: `${BASE_URL_FOR_MENU}/uploadTargetGroup`,
  campaigngetChannelFormat: `${BASE_URL_FOR_MENU}/get-channel-format`,
  campaigngetStore: `${BASE_URL_FOR_MENU}/get-store`,
  uploadCommunicationCampaignWhatsappImage: `${BASE_URL_FOR_MENU}/uploadCommunicationCampaignWhatsappImage`,
  getCampaignIdList: `${BASE_URL_FOR_MENU}/campaign-step/`,
  getAllCampaignDetails: `${BASE_URL_FOR_MENU}/campaign/`,
  getCampaignConfig: `${BASE_URL_FOR_MENU}/get-campaign-config/`,
  testCampaign: `${BASE_URL_FOR_MENU}/test-campaign`,

  // template-list
  getTemplateList: `${BASE_URL_FOR_MENU}/campaign/template-list`,
  getTemplateEvent: `${BASE_URL_FOR_MENU}/campaign/get-template-events`,

  // Feedback
  getFeedbackInsight: `${BASE_URL_FOR_MENU_FEEDBACK}/insight`,
  getConfigFeedback: `${BASE_URL_FOR_MENU_FEEDBACK}/get-config`,
  feedbackCreate: `${BASE_URL_FOR_MENU_FEEDBACK}/setting`,
  feedbackUpdate: `${BASE_URL_FOR_MENU_FEEDBACK}/setting`,
  feedbackTest: `${BASE_URL_FOR_MENU_FEEDBACK}/test`,
  getFeedBack: `${BASE_URL_FOR_MENU_FEEDBACK}/`,
  getFeedBackStep: `${BASE_URL_FOR_MENU_FEEDBACK}/get-step/`,
  feedBackCollect: `${BASE_URL_FOR_MENU_FEEDBACK}/collect`,
  getFeedbackList: `${BASE_URL_FOR_MENU_FEEDBACK}/list`,

  // Feedback Negative

  getNegativeFeedback: `${BASE_URL_FOR_MENU_FEEDBACK}/get-by-key/`,

  // FeedBack F Route
  getMemberBasic: `${BASE_URL_NO_TOKEN}/get-member-basic`,
  getMemberLoad: `${BASE_URL_FOR_MENU_FEEDBACK}/load`,
  // Invoice
  getInvoiceDetails: `${BASE_URL}/user/invoice/`,
  getInvoiceDetailKey: `${BASE_URL}/user/invoicedetail/`,
  // Import
  updateContactNumber: `${BASE_URL}/users/update-instrument`,

  // qr
  getQrProfile: `${UNAUTH_BASE_URL}/qr/profile`,
  signUP: `${UNAUTH_BASE_URL}/customer/create`,
  qrSignUp: `${UNAUTH_BASE_URL}/users/signup`,
  userSearchUnAuth: `${UNAUTH_BASE_URL}/users/search`,
  generateOTPUnAuth: `${UNAUTH_BASE_URL}/users/generate-otp`,
  validateOTPUnAuth: `${UNAUTH_BASE_URL}/users/validate-otp`,

  clientMediaInfo: `${BASE_URL_QR_CLIENT}/media-info/`,
  getCampaignByEvent: `${BASE_URL_FOR_MENU}/get-campaign-by-event`,
  getByKey: `${BASE_URL_QR_KEY}/get-by-key/`,

  // QR Code Manage
  getQRInsight: `${BASE_URL_QR_KEY}/insight`,
  getQRList: `${BASE_URL_QR_KEY}/list`,
  downloadQRCode: `${BASE_URL_QR_KEY}/download`,

  // Affiliate
  submitrequestAPI: `${BASE_URL_FOR_WF_AFFILIATE}/submit-request`,
  getUseListAutoFill: `${BASE_URL_FOR_WF_AFFILIATE}/get-userlist-autofill`,
  redeemrewardsAPI: `${BASE_URL_FOR_WF_AFFILIATE}/redeem-rewards`,
  approveRequest: `${BASE_URL_FOR_WF_AFFILIATE}/approval-request`,
  getListRequestHistory: `${BASE_URL_FOR_WF_AFFILIATE}/get-request-history`,
  getListRequestTimeline: `${BASE_URL_FOR_WF_AFFILIATE}/get-request/`,
  getProductList: `${BASE_URL_CASHIERPORTAL}/get-menu`,
  getWFMemberUserTrasactions: `${BASE_URL}/user/transaction/user-dashboard-txn-history`,

  // Digital Invoice Key
  getConfigInvoice: `${BASE_URL_INVOICE}/config/`,

};

export default endpoints;