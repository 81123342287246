import { Navigate, useRoutes } from 'react-router-dom';
// auth
import LandingWrapper from 'src/layouts/Landing/LandingWrapper';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import { CompactLayout } from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  AuthWithOTPPage,
  // QR
  AuthWithQRCodePage,
  VerifyQROTPPage,
  QRSuccessPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  // Dashboard: AFFILIATE WORKFLOW DASHBOARD
  WorkFlowBusinessDashboard,
  // DASHBOARD: AFFILIATE CUSTOMER WORKFLOW DASHBOARD
  WorkFlowCustomerDashboard,
  // LIST REQUEST
  TrackRequest,
  // GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  AccountGeneral,
  UserCardsPage,
  UserCreatePage,
  // UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  StoreListPage,
  StoreEditPage,
  StoreCreatePage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  InvoiceDetailsPageBill,
  IssueRewardPortal,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: CashierPortal
  CashierPortalPage,
  // Dashboard: RFMAnalyticsPage
  RFMAnalyticsPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  // DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
  ComponentsLandingPage,
  Success,
  // RunReport,
  NewReport,
  ImportPage,
  ImportHistory,
  CampaignPage,
  CampaignNewPage,
  CampaignSuccessPage,
  TemplatePage,
  CampaginLinkPage,
  // FeedBack
  FeedBackInsightPage,
  FeedBackStepper,
  FeedbackCapture,
  NegativeFeedbackPage,
  // Manage Request
  WorkflowListRequest,
  SubmitRequestPage,
  RedeemRequestPage,
  ALPSuccess,
  // QR Page
  QRPage,
  // Digital Invoice
  DigitalInvoicePage,
  DemoDigitalInvoice,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: '/',
      index: true,
      element: (
        <GuestGuard>
          <ComponentsLandingPage>
            <LoginPage />
          </ComponentsLandingPage>
        </GuestGuard>
      ),
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'login-with-otp', element: <AuthWithOTPPage /> },
            { path: 'login-with-otp/:clientCode', element: <AuthWithOTPPage /> },

            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // QR
    {
      path: 'qr',
      element: <CompactLayout />,
      children: [
        {
          path: 'init',
          element: (
            <GuestGuard>
              <AuthWithQRCodePage />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
        {
          path: 'success',
          element: (
            <GuestGuard>
              <QRSuccessPage />
            </GuestGuard>
          ),
        },
        // { path: 'login', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            // { path: 'reset-password', element: <ResetPasswordPage /> },
            // { path: 'login-with-otp', element: <AuthWithOTPPage /> },
            // { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyQROTPPage /> },
          ],
        },
      ],
    },

    {
      path: 'fd',
      element: <NegativeFeedbackPage />,
    },

    // Digital Invoice

    // {
    //   path: 'did',
    //   element: <DigitalInvoicePage />,
    // },

      // element: <DICompactLayout />,
        {
          path: 'did/:key',
          element: (
            <GuestGuard>
              <DigitalInvoicePage />
            </GuestGuard>
          ),
        },
        {
          path: 'di/demo',
          element: (
            <GuestGuard>
              <DemoDigitalInvoice />
            </GuestGuard>
          ),
        },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'admin', element: <GeneralAppPage /> },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        // { path: 'analytics', element: <RFMAnalyticsPage /> },
        { path: 'customer', element: <GeneralBankingPage /> },
        { path: 'booking', element: <GeneralBookingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        // { path: 'reports', element: <RunReport />},
        { path: 'wf', element: <WorkFlowBusinessDashboard /> },
        { path: 'wfcustomer', element: <WorkFlowCustomerDashboard /> },
        { path: 'track-request/:id', element: <TrackRequest /> },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        {
          path: 'cashier',
          children: [
            { element: <Navigate to="new-invoice" replace />, index: true },
            { path: 'new-invoice', element: <CashierPortalPage /> },
            { path: 'issue-reward', element: <IssueRewardPortal /> },
          ],
        },
        {
          path: 'request',
          children: [
            { element: <Navigate to="new-request" replace />, index: true },
            { path: 'list-request', element: <WorkflowListRequest /> },
            { path: 'submit-request', element: <SubmitRequestPage /> },
            { path: 'redeem-request', element: <RedeemRequestPage /> },
          ],
        },
        {
          path: 'success',
          element: <Success />,
        },
        {
          path: 'alp-success',
          element: <ALPSuccess />,
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
        { path: 'qrcode', element: <QRPage /> },
      ],
    },

    // Analytics

    {
      path: 'analytics',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/analytics/view" replace />, index: true },
        { path: 'view', element: <RFMAnalyticsPage /> },
        { path: 'reports', element: <NewReport /> },
        // { path: 'newreport', element: <RunReport /> },
      ],
    },

    {
      path: 'campaigns',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [{ element: <CampaignPage />, index: true }],
    },

    // Other

    {
      path: 'other',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/other/import" replace />, index: true },
        { path: 'import', element: <ImportPage /> },
        { path: 'import/history', element: <ImportHistory /> },
      ],
    },

    // Campaigns
    {
      path: 'campaign/new',
      element: (
        <AuthGuard>
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
          <CampaignNewPage />
        </AuthGuard>
      ),
    },
    {
      path: 'campaign/edit/:id',
      element: (
        <AuthGuard>
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
          <CampaignNewPage />
        </AuthGuard>
      ),
    },
    {
      path: 'r/:id',
      element: (
        <GuestGuard>
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
          <CampaginLinkPage />
        </GuestGuard>
      ),
    },
    {
      path: 'campaign/success',
      element: (
        <AuthGuard>
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
          <CampaignSuccessPage />
        </AuthGuard>
      ),
    },
    {
      path: 'campaign/template',
      element: (
        <AuthGuard>
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
          <TemplatePage />
        </AuthGuard>
      ),
    },

    // invoice Details page
    {
      path: 'i',
      element: (
        <GuestGuard>
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
          <InvoiceDetailsPageBill />
        </GuestGuard>
      ),
    },
    // Store
    {
      path: 'manage-store',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/manage-store/list" replace />, index: true },
        { path: 'list', element: <StoreListPage /> },
        { path: 'new', element: <StoreCreatePage /> },
        { path: ':name/edit', element: <StoreEditPage /> },
      ],
    },

    // FeedBack
    {
      path: 'feedback',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/feedback/insight" replace />, index: true },
        { path: 'insight', element: <FeedBackInsightPage /> },
      ],
    },

    {
      path: 'feedback/create',
      element: (
        <AuthGuard>
          <FeedBackStepper />
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
        </AuthGuard>
      ),
    },
    {
      path: 'feedback/edit/:id',
      element: (
        <AuthGuard>
          <FeedBackStepper />
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
        </AuthGuard>
      ),
    },
    {
      path: 'f/:id',
      element: (
        <GuestGuard>
          <FeedbackCapture />
          {/* <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper> */}
        </GuestGuard>
      ),
    },

    // {
    //   path: 'reports',
    //   element: (
    //     <AuthGuard>
    //       <LandingWrapper>
    //         <DashboardLayout />
    //       </LandingWrapper>
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to="/reports" replace />, index: true },
    //     { path: '', element: <RunReport />}
    //   ],
    // },

    // User
    {
      path: 'user',
      element: (
        <AuthGuard>
          <LandingWrapper>
            <DashboardLayout />
          </LandingWrapper>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/user/list" replace />, index: true },
        { path: 'profile', element: <AccountGeneral /> },
        { path: 'cards', element: <UserCardsPage /> },
        { path: 'list', element: <UserListPage /> },
        { path: 'new', element: <UserCreatePage /> },
        { path: ':name/edit', element: <UserEditPage /> },
        { path: 'account', element: <UserAccountPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage /> },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
        {
          path: 'components',
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: 'foundation',
              children: [
                { element: <Navigate to="/components/foundation/colors" replace />, index: true },
                { path: 'colors', element: <FoundationColorsPage /> },
                { path: 'typography', element: <FoundationTypographyPage /> },
                { path: 'shadows', element: <FoundationShadowsPage /> },
                { path: 'grid', element: <FoundationGridPage /> },
                { path: 'icons', element: <FoundationIconsPage /> },
              ],
            },
            {
              path: 'mui',
              children: [
                { element: <Navigate to="/components/mui/accordion" replace />, index: true },
                { path: 'accordion', element: <MUIAccordionPage /> },
                { path: 'alert', element: <MUIAlertPage /> },
                { path: 'autocomplete', element: <MUIAutocompletePage /> },
                { path: 'avatar', element: <MUIAvatarPage /> },
                { path: 'badge', element: <MUIBadgePage /> },
                { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
                { path: 'buttons', element: <MUIButtonsPage /> },
                { path: 'checkbox', element: <MUICheckboxPage /> },
                { path: 'chip', element: <MUIChipPage /> },
                { path: 'data-grid', element: <MUIDataGridPage /> },
                { path: 'dialog', element: <MUIDialogPage /> },
                { path: 'list', element: <MUIListPage /> },
                { path: 'menu', element: <MUIMenuPage /> },
                { path: 'pagination', element: <MUIPaginationPage /> },
                { path: 'pickers', element: <MUIPickersPage /> },
                { path: 'popover', element: <MUIPopoverPage /> },
                { path: 'progress', element: <MUIProgressPage /> },
                { path: 'radio-button', element: <MUIRadioButtonsPage /> },
                { path: 'rating', element: <MUIRatingPage /> },
                { path: 'slider', element: <MUISliderPage /> },
                { path: 'stepper', element: <MUIStepperPage /> },
                { path: 'switch', element: <MUISwitchPage /> },
                { path: 'table', element: <MUITablePage /> },
                { path: 'tabs', element: <MUITabsPage /> },
                { path: 'textfield', element: <MUITextFieldPage /> },
                { path: 'timeline', element: <MUITimelinePage /> },
                { path: 'tooltip', element: <MUITooltipPage /> },
                { path: 'transfer-list', element: <MUITransferListPage /> },
                { path: 'tree-view', element: <MUITreesViewPage /> },
              ],
            },
            {
              path: 'extra',
              children: [
                { element: <Navigate to="/components/extra/animate" replace />, index: true },
                { path: 'animate', element: <DemoAnimatePage /> },
                { path: 'carousel', element: <DemoCarouselsPage /> },
                { path: 'chart', element: <DemoChartsPage /> },
                { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
                { path: 'editor', element: <DemoEditorPage /> },
                { path: 'form-validation', element: <DemoFormValidationPage /> },
                { path: 'image', element: <DemoImagePage /> },
                { path: 'label', element: <DemoLabelPage /> },
                { path: 'lightbox', element: <DemoLightboxPage /> },
                { path: 'map', element: <DemoMapPage /> },
                // { path: 'mega-menu', element: <DemoMegaMenuPage /> },
                { path: 'multi-language', element: <DemoMultiLanguagePage /> },
                { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
                { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
                { path: 'scroll', element: <DemoScrollbarPage /> },
                { path: 'snackbar', element: <DemoSnackbarPage /> },
                { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
                { path: 'upload', element: <DemoUploadPage /> },
                { path: 'markdown', element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}